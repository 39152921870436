import React from 'react';
import { useSelector } from 'react-redux';
import {getGallery} from '../selectors';

import styled from 'styled-components';

const GalleryName = ({albumId, galleryId}) => {
    const gallery = useSelector(state => getGallery(state, galleryId));

    if (gallery == null) return null;

    return <Title>{gallery?.name}</Title>;
};

const Title = styled.h1`
    text-align: center;
    margin: 100px 0 80px;
`;

export default GalleryName;

