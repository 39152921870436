import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../actions';
import {getAuthToken} from '../selectors';

import styled from 'styled-components';

const AddNewAlbum = () => {
    const [name, setName] = useState('');

    const token = useSelector(getAuthToken);

    const dispatch = useDispatch();

    if (!token) return null;

    const create = () => {
        if (name === '') return;
        dispatch(actions.albums.create(name));
    };

    return (
        <Container>
            <Title>Create New Album</Title>
            <Form>
                <Input name="name" placeholder="Album name" onChange={e => setName(e.target.value)} />
                <Submit onClick={() => create()} disabled={name === ''}>Create</Submit>
            </Form>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 300px;
    padding: 40px 40px 0 40px;
`;


const Form = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h3`
    display: flex;
    text-align: center;
    color: #333333;
    margin: 0 0 10px 0;
`;

const Input = styled.input`
    // display: block;
    background: #fff;
    // width: 100%;
    padding: 10px;
    margin: 15px 0;
    border-radius: 5px;
    font-size: 16px;
`;

const Button = styled.button`
    cursor: pointer;
    color: #f45b69;
    background-color: white;
    text-transform: uppercase;
    width: 80px;
    padding: 10px 0;
    transition: background-color .3s;
    border-radius: 5px;
    font-size: 16px;
    border: 0;
`;

const Submit = styled(Button)`
    background: #fff;
    &:hover {
        background: #ccc;
    }
`;


export default AddNewAlbum;

