import axios from 'axios';

import {apiPath} from '../config/api';

const UPLOAD_STATE = {
    Progress: 'progress',
    Size: 'size',
    Done: 'done',
};

const getImageVideoSize = (type, src) => new Promise((resolve, reject) => {
    if (type === 'image') {
        const img = document.createElement('img');
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
            });
        };
        img.src = src;
    }
    else {
        const video = document.createElement('video');
        video.onload = () => {
            resolve({
                width: video.videoWidth,
                height: video.videoHeight,
            });
        };
        video.src = src;
    }
});

const uploadFile = ({
    authToken,
    file,
    type,
    folder,
    width,
    height,
    index = 0,
    stateChangeCback = (type, file, index, data) => {},
}) => new Promise((resolve, reject) => {
    const { preview } = file;

    const signFormData = {
        fileName: file.name,
        fileType: type || file.type,
        folder,
    };


    // Get headers
    // TODO
    const authHeaders = {
        'X-Coder-Token': authToken,
    };

    axios.post(`${apiPath}upload/sign/`, signFormData, {
        headers: authHeaders,
    })
    .then(response => {
        const returnData = response.data.data.returnData;
        const { signedRequest, url, key } = returnData;
        const imageKeys = key.split('/');
        const publicId = imageKeys[imageKeys.length - 1];

        const headers = {
            'content-type': 'image/*',
        };

        const fileType = signFormData.fileType.startsWith('video/') ? 'video' : 'image';

        if (fileType === 'video') {
            headers['content-type'] = 'video/*';
        }

        // Put the fileType in the headers for the upload
        const options = {
            headers,
            onUploadProgress: (progressEvent) => {
                const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                stateChangeCback(UPLOAD_STATE.Progress, file, index, {percent});
            },
        };

        if (width && height) {
            axios.put(signedRequest, file, options)
            .then(result => {
                const data = {
                    tmpId: file.tmpId || '',
                    url,
                    fileType,
                    public_id: publicId,
                    original_filename: file.name,
                    type: 's3',
                    width,
                    height,
                };

                stateChangeCback(UPLOAD_STATE.Done, file, index, data );
                resolve(data);
            })
            .catch(error => {
                console.log('ERROR', error);
            });
        }
        else {
            getImageVideoSize(fileType, preview)
            .then(size => {
                stateChangeCback(UPLOAD_STATE.Size, file, index, size );

                axios.put(signedRequest, file, options)
                .then(result => {
                    const data = {
                        tmpId: file.tmpId || '',
                        url,
                        fileType,
                        public_id: publicId,
                        original_filename: file.name,
                        type: 's3',
                        ...size,
                    };

                    stateChangeCback(UPLOAD_STATE.Done, file, index, data );
                    resolve(data);
                })
                .catch(error => {
                    console.log('ERROR', error);
                });
            })
            .catch((error) => {
                console.log('ERROR getImageVideoSize', error);
            });
        }

        // axios.put(signedRequest, file, options)
        // .then(result => {
        //     getImageVideoSize(fileType, url)
        //     .then(size => {
        //         const data = {
        //             tmpId: file.tmpId || '',
        //             url,
        //             fileType,
        //             public_id: key,
        //             original_filename: file.name,
        //             type: 's3',
        //             ...size,
        //         };

        //         stateChangeCback(UPLOAD_STATE.Done, file, index, data );
        //         resolve(data);
        //     })
        //     .catch((error) => {
        //         console.log('ERROR getImageVideoSize', error);
        //     });
        // })
        // .catch(error => {
        //     console.log('ERROR', error);
        // });
    })
    .catch(error => {
        console.log('Axios error', JSON.stringify(error));
    });
});

const uploadFiles = ({
    files,
    stateChangeCback = (type, file, index, data) => {},
}) => new Promise((resolve, reject) => {
    const uploadingFiles = [];
    files.map((file, index) => {
        uploadingFiles.push(uploadFile({ file, index, stateChangeCback }));
    });

    Promise.all(uploadingFiles)
    .then((values) => {
        resolve(values);
    })
    .catch((error) => {
        console.log('All error', error);
    });
});

export {
    UPLOAD_STATE,
    uploadFile,
    uploadFiles,
};
