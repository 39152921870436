import React from 'react';
import { useSelector } from 'react-redux';
import {getAlbum} from '../selectors';

import styled from 'styled-components';

const AlbumName = ({id}) => {
    const album = useSelector(state => getAlbum(state, id));

    if (album == null) return null;

    return <Title>{album?.name}</Title>;
};

const Title = styled.h1`
    text-align: center;
    margin: 100px 0 80px;
`;

export default AlbumName;

