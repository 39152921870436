import { call, select } from 'redux-saga/effects';
import axios from 'axios';

import {apiPath} from '../config/api';

const responseHandler = fetchPromise => fetchPromise.then(
    response => ({
        response,
    }),
).catch(error => ({
    error,
}));

/**
 * Load Api Data
 */
export const Get = (url, headers) => responseHandler(
    axios({
        method: 'get',
        url,
        timeout: 15000,
        headers,
    }),
);

export function* reader({ ...action }) {
    // Create URL
    const url = `${apiPath}${action.endpoint}`;

    // Get headers
    const headers = {
        ...action.header,
    };

    return yield call(Get, url, headers);
}

/**
 * Create Api Data
 */
export const Post = (url, data, headers) => responseHandler(
    axios({
        method: 'post',
        url,
        data,
        timeout: 15000,
        headers,
    }),
);

export function* creator({ ...action }) {
    // Create URL
    const url = `${apiPath}${action.endpoint}`;
    const {data} = action;

    // Get headers
    const headers = {
        ...action.header,
    };

    return yield call(Post, url, data, headers);
}

/**
 * Update Api Data
 */
export const Put = (url, data, headers) => responseHandler(
    axios({
        method: 'put',
        url,
        data,
        timeout: 15000,
        headers,
    }),
);

export function* updater({ ...action }) {
    // Create URL
    const url = `${apiPath}${action.endpoint}`;
    const {data} = action;

    // Get headers
    const headers = {
        ...action.header,
    };

    return yield call(Put, url, data, headers);
}



/**
 * Delete Api Data
 */
export const Delete = (url, headers) => responseHandler(
    axios({
        method: 'delete',
        url,
        timeout: 15000,
        headers,
    }),
);

export function* deleter({ ...action }) {
    // Create URL
    const url = `${apiPath}${action.endpoint}`;

    // Get headers
    const headers = {
        ...action.header,
    };

    return yield call(Delete, url, headers);
}
