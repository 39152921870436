import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';

import { useDropzone } from 'react-dropzone';

import {getAction, ActionTypes, albums} from '../actions';
import {getAuthToken, getAlbum, getUploaderItems, getGallery} from '../selectors';

import {ImageTools} from '../utils/image';

import { UPLOAD_STATE, uploadFile } from '../utils/uploader';

const sizeOptions = [{
    name: 'thumbnail',
    size: 600,
    quality: 0.75,
}, {
    name: 'full',
    size: 1200,
    quality: 0.87,
}];


const PhotoUploader = ({albumId, galleryId}) => {
    const dispatch = useDispatch();

    const gallery = useSelector(state => getGallery(state, galleryId));
    const token = useSelector(getAuthToken);

    let galleryKey = `${albumId}/${galleryId}`;

    const tmpImages = useSelector(getUploaderItems);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        // accept: 'image/*, video/*',
        onDrop: acceptedFiles => {
            const droppedFiles = acceptedFiles.map(file => Object.assign(file, {
                percent: 0,
                tmpId: Math.random().toString(36).substr(2, 5),
                preview: URL.createObjectURL(file),
                width: 0,
                height: 0,
            }));

            for(let a = 0; a < droppedFiles.length; a += 1) {
                const item = droppedFiles[a];

                dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), id: item.tmpId, data: item });

                for (let b = 0; b < sizeOptions.length; b += 1) {
                    const { size, name, quality } = sizeOptions[b];

                    ImageTools.rotate(item, (blob, width, height) => {
                        const newFile = Object.assign(new File([blob], `${item.name}`, {
                            type: item.type,
                        }), {
                            tmpId: item.tmpId,
                        });

                        uploadFile({
                            authToken: token,
                            file: newFile,
                            folder: `${galleryKey}/s${size}`,
                            width,
                            height,
                            type: item.type,
                            stateChangeCback: (type, file, index, data) => {
                                switch (type) {
                                    case UPLOAD_STATE.Progress:
                                        if (name === 'full') {
                                            const updatedData = {
                                                ...data,
                                                tmpId: item.tmpId,
                                                width: parseInt(Math.floor(width + 0.5)),
                                                height: parseInt(Math.floor(height + 0.5)),
                                            };
                                            dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), id: file.tmpId, data: updatedData });
                                        }
                                        break;
                                    case UPLOAD_STATE.Done:
                                        const updatedData = {};
                                        if (name === 'full') {
                                            updatedData.url = data.url;
                                            updatedData.fileType = data.fileType;
                                            updatedData.publicId = data.public_id;
                                            updatedData.width = parseInt(Math.floor(width + 0.5));
                                            updatedData.height = parseInt(Math.floor(height + 0.5));
                                        }
                                        else {
                                            updatedData[name] = {
                                                url: data.url,
                                                fileType: data.fileType,
                                                publicId: data.public_id,
                                                width: parseInt(Math.floor(width + 0.5)),
                                                height: parseInt(Math.floor(height + 0.5)),
                                            };
                                        }
                                        dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), id: file.tmpId, data: updatedData });
                                    break;
                                    default:
                                        break;
                                }
                            },
                        });
                    }, item.type, quality, size);
                }
            }
        },
    });

    if (!token) return null;

    const save = async (event) => {
        event.preventDefault();

        const imagesOrder = [
            ...gallery?.imagesOrder || [],
        ];

        dispatch(albums.saveGalleryImages(albumId, galleryId, tmpImages, imagesOrder));
    }

    return (
        <Container>
            <ThumbsWrapper>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop the image here, or click to select one</p>
                </div>
            </ThumbsWrapper>

            <Submit onClick={save}>Save</Submit>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 800px;
    margin: 0 auto;
    padding: 40px 40px 0 40px;
`;

const Button = styled.button`
    cursor: pointer;
    color: #f45b69;
    background-color: white;
    text-transform: uppercase;
    width: 80px;
    padding: 10px 0;
    transition: background-color .3s;
    border-radius: 5px;
    font-size: 16px;
    border: 0;
`;

const Submit = styled(Button)`
    background: #fff;
    &:hover {
        background: #ccc;
    }
`;

const ThumbsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export default PhotoUploader;

