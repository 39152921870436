// @flow
import { getAction, ActionTypes } from '../actions';

export const initialState = Object.freeze({
    locale: 'en-GB',
});

export const reducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case getAction('LOCALE', ActionTypes.SET): {
            return {
                ...state,
                locale: data || {},
            };
        }
        default:
            break;
    }

    return state;
};
