// @flow
import { getAction, ActionTypes } from '../actions';

export const initialState = Object.freeze({
    albums: [],
    album: {},
    gallery: {},
});

export const reducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case getAction('ALBUMS', ActionTypes.LOAD, true):
            return {
                ...state,
                albums: [
                    ...data,
                ],
            };
        case getAction('ALBUM', ActionTypes.LOAD, true):
        case getAction('ALBUM', ActionTypes.CREATE, true):
        case getAction('ALBUM', ActionTypes.UPDATE, true):
            return {
                ...state,
                album: {
                    ...state.album,
                    [data.id]: {
                        ...data,
                    }
                }
            };
        case getAction('GALLERY', ActionTypes.LOAD, true):
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    [data.id]: {
                        ...data,
                    }
                }
            };
        default:
            break;
    }

    return state;
};
