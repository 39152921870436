import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ClipLoader from "react-spinners/ClipLoader";

import styled from 'styled-components';

import * as actions from '../actions';
import {getAlbum, isAdmin} from '../selectors';

import GalleryName from '../components/GalleryName';
import Gallery from '../components/Gallery';
import GalleryDetails from '../components/GalleryDetails';
import PhotoUploader from '../components/PhotoUploader';


const GalleryPage = (props) => {
    const { match: { params } } = props;

    const {albumId, galleryId} = params;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.albums.loadAlbum(albumId));
        dispatch(actions.albums.loadGallery(albumId, galleryId));
    }, [dispatch, albumId, galleryId]);

    const album = useSelector(state => getAlbum(state, albumId));

    const admin = useSelector(isAdmin);

    if (album == null || album?.id == null) return <Viewport><ClipLoader color={'#fb3'} loading={true} size={150} /></Viewport>

    return (
        <div>
            <GalleryName albumId={albumId} galleryId={galleryId} />
            {admin && <GalleryDetails albumId={albumId} galleryId={galleryId}  />}
            {admin && <PhotoUploader albumId={albumId} galleryId={galleryId} />}
            <Gallery albumId={albumId} galleryId={galleryId} />
        </div>
    );
};

const Viewport = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-content: center;
    justify-content: center;
`;


export default GalleryPage;
