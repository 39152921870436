// https://github.com/armand1m/react-with-firebase-auth/blob/master/src/index.tsx
import React, { useState, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from "react-cookie"

import styled from 'styled-components';

import * as actions from '../actions';
import { getAction, ActionTypes } from '../actions';

const AuthPage = () => {
    const [cookie, setCookie] = useCookies(['token']);

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const auth = useSelector(state => state.auth);

    const cback = useCallback((token) => {
        setCookie('token', token, {
            path: '/',
            maxAge: 3600 * 24, // Expires after 24hr
            sameSite: true,
        });
    }, [setCookie]);

    const login = () => {
        dispatch(actions.auth.authenticate(email, password, cback));
    };

    const signOut = () => {
        dispatch({
            type: getAction('TOKEN', ActionTypes.CREATE, true),
            data: {
                token: null,
            },
        });
    };

    const loggedIn = auth?.token != null;

    return (
        <Wrapper>
            <Container>
                {loggedIn && (
                    <>
                        <Logout onClick={() => signOut()}>Sign Out</Logout>
                        <GoBack href="/">&larr; Back to Album</GoBack>
                    </>
                )}
                {!loggedIn && (
                    <>
                        <Input name="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
                        <Input type="password" name="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                        <SignIn onClick={() => login()}>Sign In</SignIn>
                    </>
                )}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 40px 30px 30px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    transform: translate(-50%,-50%);
    transition: transform .3s,box-shadow .3s;
    box-shadow: 1px 5px 10px #f45b69;
`;

const Button = styled.button`
    cursor: pointer;
    color: #f45b69;
    background-color: white;
    text-transform: uppercase;
    width: 80px;
    padding: 10px 0;
    transition: background-color .3s;
    border-radius: 5px;
    font-size: 16px;
    border: 0;
`;

const GoBack = styled.a`
    cursor: pointer;
    color: #2d5430;
    background-color: white;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    text-decoration: none;
    padding: 10px 0;
    transition: background-color .3s;
    border-radius: 5px;
    font-size: 16px;
    border: 0;
`;


const SignIn = styled(Button)`
`;

const Logout = styled(Button)`
    background: #fff;
    &:hover {
        background: #ccc;
    }
`;

const Input = styled.input`
    display: block;
    background: #fff;
    padding: 10px;
    margin: 15px 0;
    border-radius: 5px;
    font-size: 16px;
`;

export default AuthPage;

