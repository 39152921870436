import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';

import rootReducer from '../reducers';
import sagas, { waitAll } from '../sagas';

import { createBrowserHistory } from 'history'

// import environment from '../environment';

export const configureStore = () => {
    const history = createBrowserHistory();

    const devtools =
        typeof window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });

    const composeEnhancers = devtools || compose;

    const routerMiddleware = createRouterMiddleware(history);

    const sagaMiddleware = createSagaMiddleware();

    const middleware = [
        sagaMiddleware,
        routerMiddleware,
    ];

    const store = createStore(
        rootReducer(history),
        composeEnhancers(applyMiddleware(...middleware)),
    );

    sagaMiddleware.run(sagas);

    // let rootSaga = sagaMiddleware.run(sagas);
    // const preloadSaga = sagaMiddleware.run(waitAll(preloadSagas));
    // const stopSagas = () => store.dispatch(END);

    // if (process.env.NODE_ENV !== 'production') {
    //     if (module.hot) {
    //         module.hot.accept('../reducers', () =>
    //             store.replaceReducer(require('../reducers').default(history))
    //         );
    //         module.hot.accept('../sagas', () => {
    //             const nextSagas = require('../sagas').default;
    //             rootSaga.cancel();
    //             rootSaga.done.then(() => {
    //                 rootSaga = sagaMiddleware.run(nextSagas);
    //             });
    //         });
    //     }
    // }

    return { store, history };
    // return { store, rootSaga, preloadSaga, stopSagas };
};

export default configureStore;
