import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ClipLoader from "react-spinners/ClipLoader";

import styled from 'styled-components';

import * as actions from '../actions';
import {getAlbum, isAdmin} from '../selectors';

import AlbumName from '../components/AlbumName';
import AlbumDetails from '../components/AlbumDetails';
import AddNewGallery from '../components/AddNewGallery';
import Galleries from '../components/Galleries';

const AlbumPage = (props) => {
    const { match: { params } } = props;
    const {albumId} = params;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.albums.loadAlbum(albumId));
    }, [dispatch, albumId]);

    const album = useSelector(state => getAlbum(state, albumId));

    const admin = useSelector(isAdmin);

    if (album == null || album?.id == null) return <Viewport><ClipLoader color={'#fb3'} loading={true} size={150} /></Viewport>

    return (
        <div>
            <AlbumName id={albumId} />
            {admin && <AddNewGallery id={albumId} />}
            <Galleries id={albumId} />
            {admin && <AlbumDetails id={albumId} />}
        </div>
    );
};

const Viewport = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-content: center;
    justify-content: center;
`;


export default AlbumPage;
