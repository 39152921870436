// @flow
import { getAction, ActionTypes } from '../actions';

const defaultState = {
    verified: false,
    refreshToken: null,
    token: null,
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case getAction('LOGIN', ActionTypes.CREATE, true):
            return {
                ...state,
                refreshToken: action.data.token,
            };
        case getAction('TOKEN', ActionTypes.CREATE, true):
            return {
                ...state,
                token: action.data.token,
                verified: true,
            };
        default:
            return state;
    }
};
