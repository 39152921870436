// @flow
import { getAction, ActionTypes } from '../actions';

export const initialState = [];

export const reducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case getAction('USERS', ActionTypes.LOAD, true): {
            return {
                ...data,
            };
        }
        default:
            break;
    }

    return state;
};
