import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import {reducer as appReducer} from './app';
import {reducer as albumsReducer} from './albums';
import {reducer as authReducer} from './auth';
import {reducer as uploaderReducer} from './uploader';
import {reducer as usersReducer} from './users';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    app: appReducer,
    albums: albumsReducer,
    auth: authReducer,
    uploader: uploaderReducer,
    users: usersReducer,
});

export default rootReducer

