import {getAuthToken} from './auth';
import jwt_decode from "jwt-decode";
import md5 from 'md5';

export const getUsers = state => state.users || [];
export const isAdmin = state => {
    const token = getAuthToken(state);
    try {
        const user = jwt_decode(token);
        return md5(user?.email ?? '') === '9e9ef108fe481e1a3bd3c86bbbe2ab23';
    }
    catch(e) {
        return false;
    }
}
