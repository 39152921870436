import React from 'react';
import { useSelector } from 'react-redux';
import {getAlbum, getAuthToken} from '../selectors';
import {getImagePreview} from '../utils/image';

import styled from 'styled-components';

const Galleries = ({id}) => {
    const album = useSelector(state => getAlbum(state, id));
    const token = useSelector(getAuthToken);

    const galleries = album?.galleries || [];
    const allGalleries = token == null ? galleries.filter(gallery => gallery.enabled) : galleries;

    if (album == null) return null;

    return (
        <Wrapper>
            {allGalleries.map(gallery => (
                <Gallery key={gallery.id} href={`/albums/${album.id}/galleries/${gallery.id}`}>
                    <Title>{gallery.name}</Title>
                    <ThumbsWrapper>
                        <Thumb>
                            <ThumbInner image={getImagePreview(id, gallery.image, gallery.id)} />
                        </Thumb>
                    </ThumbsWrapper>
                </Gallery>
            ))}
        </Wrapper>
    )
};

const Wrapper = styled.div`
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Gallery = styled.a`
    position: relative;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    color: #748923;

    transition: all 150ms ease-in-out;

    &:hover {
        box-shadow: 1px 1px 4px #666;
    }
`;

const Title = styled.h4`
    text-align: center;
    margin: 20px 0 20px;
`;

const ThumbsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 140px;
    width: 160px;
`;

const Thumb = styled.div`
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
`;

const ThumbInner = styled.div`
    display: flex;
    min-width: 0px;
    overflow: hidden;
    height: 140px;
    width: 160px;
    background-image: url(${({image}) => image});
    background-size: cover;
`;


export default Galleries;

