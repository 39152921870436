// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


import React, { useContext, createContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import { Provider } from 'react-redux';

// This example has 3 pages: a public page, a protected
// page, and a login screen. In order to see the protected
// page, you must first login. Pretty standard stuff.
//
// First, visit the public page. Then, visit the protected
// page. You're not yet logged in, so you are redirected
// to the login page. After you login, you are redirected
// back to the protected page.
//
// Notice the URL change each time. If you click the back
// button at this point, would you expect to go back to the
// login page? No! You're already logged in. Try it out,
// and you'll see you go back to the page you visited
// just *before* logging in, the public page.

// import { ConnectedRouter } from 'connected-react-router'
import { ConnectedRouter } from 'connected-react-router/immutable';

import { CookiesProvider, useCookies } from "react-cookie"
import styled from 'styled-components';

import {configureStore} from './config/store';

import loginIcon from './assets/login-icon.png';

import Home from './routes/Home';
import Album from './routes/Album';
import Gallery from './routes/Gallery';
import Auth from './routes/Auth';

import { useDispatch, useSelector } from 'react-redux';
import {getAction, ActionTypes} from './actions';

const { store, history } = configureStore();

const App = () => {
  const [cookie] = useCookies(['token']);
  const token = cookie?.token;
  const dispatch = useDispatch();

  const verified = useSelector(state => state.auth.verified);

  // Load token cookie and set it to auth store
  useEffect(() => {
    dispatch({
      type: getAction('TOKEN', ActionTypes.CREATE),
      token,
    });
  }, [dispatch, token]);

  if (!verified) return null;

  return (
    <>
      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route exact path="/albums/:albumId/" render={(props) => <Album {...props} />} />
        <Route exact path="/albums/:albumId/galleries/:galleryId" render={(props) => <Gallery {...props} />} />
        <Route exact path="/login" render={() => <Auth />} />
      </Switch>
      {token == null && (
        <LoginIcon href="/login">
          <img src={loginIcon} alt="Login" />
        </LoginIcon>
      )}
    </>
  );
};

const Root = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  );
};

export default Root;


// export default function AuthExample() {
//   return (
//     <ProvideAuth>
//       <Router>
//         <div>
//           <AuthButton />

//           <ul>
//             <li>
//               <Link to="/public">Public Page</Link>
//             </li>
//             <li>
//               <Link to="/protected">Protected Page</Link>
//             </li>
//           </ul>

//           <Switch>
//             <Route path="/public">
//               <PublicPage />
//             </Route>
//             <Route path="/login">
//               <LoginPage />
//             </Route>
//             <PrivateRoute path="/protected">
//               <ProtectedPage />
//             </PrivateRoute>
//           </Switch>
//         </div>
//       </Router>
//     </ProvideAuth>
//   );
// }

// const fakeAuth = {
//   isAuthenticated: false,
//   signin(cb) {
//     fakeAuth.isAuthenticated = true;
//     setTimeout(cb, 100); // fake async
//   },
//   signout(cb) {
//     fakeAuth.isAuthenticated = false;
//     setTimeout(cb, 100);
//   }
// };

// /** For more details on
//  * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
//  * refer to: https://usehooks.com/useAuth/
//  */
// const authContext = createContext();

// function ProvideAuth({ children }) {
//   const auth = useProvideAuth();
//   return (
//     <authContext.Provider value={auth}>
//       {children}
//     </authContext.Provider>
//   );
// }

// function useAuth() {
//   return useContext(authContext);
// }

// function useProvideAuth() {
//   const [user, setUser] = useState(null);

//   const signin = cb => {
//     return fakeAuth.signin(() => {
//       setUser("user");
//       cb();
//     });
//   };

//   const signout = cb => {
//     return fakeAuth.signout(() => {
//       setUser(null);
//       cb();
//     });
//   };

//   return {
//     user,
//     signin,
//     signout
//   };
// }

// function AuthButton() {
//   let history = useHistory();
//   let auth = useAuth();

//   return auth.user ? (
//     <p>
//       Welcome!{" "}
//       <button
//         onClick={() => {
//           auth.signout(() => history.push("/"));
//         }}
//       >
//         Sign out
//       </button>
//     </p>
//   ) : (
//     <p>You are not logged in.</p>
//   );
// }

// // A wrapper for <Route> that redirects to the login
// // screen if you're not yet authenticated.
// function PrivateRoute({ children, ...rest }) {
//   let auth = useAuth();
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         auth.user ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/login",
//               state: { from: location }
//             }}
//           />
//         )
//       }
//     />
//   );
// }

// function PublicPage() {
//   return <h3>Public</h3>;
// }

// function ProtectedPage() {
//   return <h3>Protected</h3>;
// }

// function LoginPage() {
//   let history = useHistory();
//   let location = useLocation();
//   let auth = useAuth();

//   let { from } = location.state || { from: { pathname: "/" } };
//   let login = () => {
//     auth.signin(() => {
//       history.replace(from);
//     });
//   };

//   return (
//     <div>
//       <p>You must log in to view the page at {from.pathname}</p>
//       <button onClick={login}>Log in</button>
//     </div>
//   );
// }

const LoginIcon = styled.a`
  position: fixed;
  top: 16px;
  right: 16px;
  padding: 8px;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 0 4px #aaa;
  }

  > img {
    width: 24px;
    height: 24px;
  }
`;
