import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import MultiSelect from "react-multi-select-component";
import styled from 'styled-components';

import { useSpring, a } from 'react-spring';
import { useDropzone } from 'react-dropzone';

import * as actions from '../actions';
import {getAuthToken, getAlbum, getUsers} from '../selectors';

import {getImagePreview, ImageTools} from '../utils/image';

import Wysiwyg from '../components/Wysiwyg';
import Switch from '../components/Switch';

import { UPLOAD_STATE, uploadFile } from '../utils/uploader';

const AlbumDetails = ({id}) => {
    const dispatch = useDispatch();

    // TODO
    // useEffect(() => {
    //     dispatch(actions.users.load());
    // }, [dispatch]);

    const album = useSelector(state => getAlbum(state, id));
    const token = useSelector(getAuthToken);
    const users = useSelector(getUsers);

    const [name, setName] = useState(album?.name);
    const [enabled, setEnabled] = useState(album?.enabled || false);
    const [restricted, setRestricted] = useState(album?.restricted || false);
    const [allowlist, setAllowlist] = useState(album?.allowlist || []);
    const [image, setImage] = useState(album?.image || null);
    const [startDate, setStartDate] = useState(album?.startDate || '');
    const [endDate, setEndDate] = useState(album?.endDate || '');
    const [imageOpacityState, setImageOpacityState] = useState(image ? 1 : 0);

    const { imageOpacity } = useSpring({
        // config: { mass: 1, tension: 170, friction: 70 },
        from: { imageOpacity: 0 },
        imageOpacity: imageOpacityState,
    });


    let contentState = EditorState.createEmpty();
    if (album?.description) {
        const rawContent = convertFromRaw(JSON.parse(album?.description.raw));
        contentState = EditorState.createWithContent(rawContent);
    }
    const [editorState, setEditorState] = useState(contentState);

    const initialFiles = [];
    if (image) {
        initialFiles.push({
            name: image.public_id,
            preview: getImagePreview(id, album?.image),
        });
    }
    const [files, setFiles] = useState(initialFiles);

    let galleryKey = `${id}`;

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        // accept: 'image/*, video/*',
        onDrop: acceptedFiles => {
            const filteredFiles = [acceptedFiles[0]];
            setFiles(filteredFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            const item = filteredFiles[0];

            const size = 1200;
            const quality = 0.87

            ImageTools.rotate(item, (blob, width, height) => {
                const newFile = Object.assign(new File([blob], `${item.name}`, {
                    type: item.type,
                }), {
                    tmpId: item.tmpId,
                });

                uploadFile({
                    authToken: token,
                    file: newFile,
                    folder: galleryKey,
                    width,
                    height,
                    type: item.type,
                    stateChangeCback: (type, file, index, data) => {
                        switch (type) {
                            case UPLOAD_STATE.Progress:
                                setImageOpacityState(data.percent / 150.0);
                                break;
                            case UPLOAD_STATE.Done:
                                setImageOpacityState(1);
                                break;
                            default:
                                break;
                        }
                    },
                })
                .then(data => {
                    setImage({
                        type: data.fileType,
                        publicId: data.public_id,
                        width: data.width,
                        height: data.height,
                    });
                });
            }, item.type, quality, size);
        },
    });

    if (!token) return null;

    // TODO
    // const usersMultiSelect = users.map(user => ({
    //     label: user.name,
    //     value: user.id,
    // }));

    const thumbs = files.map(file => (
        <Thumb key={file.name ?? file.preview} style={{
            opacity: imageOpacity,
        }}>
            <ThumbInner>
                <Img src={file.preview} />
            </ThumbInner>
        </Thumb>
    ));



    const save = (event) => {
        event.preventDefault();

        const descriptionRaw = convertToRaw(editorState.getCurrentContent());

        const data = {
            enabled,
            name,
            startDate,
            endDate,
            image,
            description: {
                raw: JSON.stringify(descriptionRaw),
                html: draftToHtml(descriptionRaw),
            },
        };

        if (album?.isNew) {
            dispatch(actions.albums.create(data, () => {
                console.log('CREATED');
            }));
        }
        else {
            dispatch(actions.albums.update(album?.id, data, () => {
                console.log('SAVED');
            }));
        }
    }

    return (
        <Container>
            <Title></Title>
            <Form>
                <SectionTitle>Status</SectionTitle>
                <Switch enabled={enabled} onToggle={setEnabled} />

                <SectionTitle>Name</SectionTitle>
                <Input name="name" placeholder="Album name" onChange={e => setName(e.target.value)} value={name} />

                <SectionTitle>Start Date</SectionTitle>
                <Input
                    type="date"
                    name="startDate"
                    autocomplete="off"
                    spellcheck="false"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <SectionTitle>End Date</SectionTitle>
                <Input
                    type="date"
                    name="endDate"
                    autocomplete="off"
                    spellcheck="false"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />

                <SectionTitle>Description</SectionTitle>
                <Wysiwyg
                    editorState={editorState}
                    onStateChange={(state) => setEditorState(state)}
                />

                <SectionTitle>Restricted</SectionTitle>
                <Switch enabled={restricted} onToggle={setRestricted} />

                {/* {restricted && (
                    <>
                        <SectionTitle>Restricted</SectionTitle>
                        <MultiSelect
                            options={usersMultiSelect}
                            value={allowlist}
                            onChange={setAllowlist}
                            labelledBy="Select user"
                        />
                    </>
                )} */}

                <ThumbsWrapper>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop the image here, or click to select one</p>
                    </div>
                    <ThumbsContainer>
                        {thumbs}
                    </ThumbsContainer>
                </ThumbsWrapper>

                <Submit onClick={save} disabled={name === ''}>Save</Submit>
            </Form>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 800px;
    margin: 0 auto;
    padding: 40px 40px 0 40px;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h2`
    display: flex;
    text-align: center;
    color: #333333;
    margin: 0 0 10px 0;
`;

const Input = styled.input`
    // display: block;
    background: #fff;
    // width: 100%;
    padding: 10px;
    margin: 15px 0;
    border-radius: 5px;
    font-size: 16px;
`;

const Button = styled.button`
    cursor: pointer;
    color: #f45b69;
    background-color: white;
    text-transform: uppercase;
    width: 80px;
    padding: 10px 0;
    transition: background-color .3s;
    border-radius: 5px;
    font-size: 16px;
    border: 0;
`;

const Submit = styled(Button)`
    background: #fff;
    &:hover {
        background: #ccc;
    }
`;

const SectionTitle = styled.div`
    display: flex;
    text-align: center;
    color: #333333;
    margin: 16px 0 8px 0;
    font-size: 18px;
    font-weight: 600;
`;

const ThumbsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ThumbsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
`;

const Thumb = styled(a.div)`
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    // width: 100px;
    // height: 100px;
    padding: 4px;
    box-sizing: border-box;
    opacity: 0;

    width: 100%;
    height: 100%;
`;

const ThumbInner = styled.div`
    display: flex;
    min-width: 0px;
    overflow: hidden;

    width: 100%;
    height: 100%;
`;

const Img = styled.img`
    display: block;
    // width: auto;
    // height: 100%;

    width: 100%;
    height: 100%;
`;

export default AlbumDetails;

