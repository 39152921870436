import { call, put, takeEvery, select } from 'redux-saga/effects';

import { getAction, ActionTypes } from '../actions';
import { getUsers, getAuthToken } from '../selectors';
import { reader } from './common';

const loadUsers = function* loadUsers(action) {
    const authToken = yield select(getAuthToken);

    const cached = yield select(getUsers);
    if (Object.keys(cached).length === 0) {
        const data = yield call(reader, {
            endpoint: 'users',
            header: authToken ? {
                'X-Coder-Token': authToken,
            } : {},
        });

        yield put({
            type: getAction('USERS', ActionTypes.LOAD, true),
            data: data.response.data,
        });
    }
};

const rootSaga = function* () {
    yield takeEvery(getAction('USERS', ActionTypes.LOAD), loadUsers);
}

export default rootSaga;
