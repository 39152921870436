import AddNewAlbum from '../components/AddNewAlbum'
import Albums from '../components/Albums'

import styled from 'styled-components';

import { useSelector } from 'react-redux';
import {isAdmin} from '../selectors';

const HomePage = () => {
    const admin = useSelector(isAdmin);

    return (
        <Wrapper>
            <Viewport>
                {admin && <AddNewAlbum />}
                <Albums />
            </Viewport>
        </Wrapper>
    );
};


const Wrapper = styled.div`
`;

const Viewport = styled.div`
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
`;


export default HomePage;
