import { getAction, ActionTypes } from './helpers';

export const load = () => ({
    type: getAction('ALBUMS', ActionTypes.LOAD),
});

export const create = (name, cback) => ({
    type: getAction('ALBUM', ActionTypes.CREATE),
    data: {
        name,
    }, cback,
});

export const update = (id, data, cback) => ({
    type: getAction('ALBUM', ActionTypes.UPDATE),
    id, data, cback,
});

export const loadAlbum = (id) => ({
    type: getAction('ALBUM', ActionTypes.LOAD),
    id,
});


export const createGallery = (id, name, cback) => ({
    type: getAction('GALLERY', ActionTypes.CREATE),
    id,
    data: {
        name,
    }, cback,
});

export const loadGallery = (albumId, galleryId) => ({
    type: getAction('GALLERY', ActionTypes.LOAD),
    albumId, galleryId,
});

export const updateGallery = (albumId, galleryId, data) => ({
    type: getAction('GALLERY', ActionTypes.UPDATE),
    albumId, galleryId, data,
});

export const saveGalleryImages = (albumId, galleryId, images, imagesOrder) => ({
    type: getAction('GALLERY_IMAGES', ActionTypes.UPDATE),
    albumId, galleryId, images, imagesOrder,
});
