import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../actions';
import {getAlbums, getAuthToken, isAdmin} from '../selectors';

import { getImagePreview } from '../utils/image';

import styled from 'styled-components';

const Albums = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.albums.load());
    }, [dispatch]);

    const token = useSelector(getAuthToken);
    const admin = useSelector(isAdmin);

    const albums = useSelector(getAlbums);
    const allAlbums = token == null ? albums.filter(album => album.enabled) : albums;

    return (
        <Wrapper>
            <Slider>
                {allAlbums.map(album => {
                    console.log('album', album)
                    let albumLink = `/albums/${album.id}`;
                    if (album?.galleries?.length === 1) {
                        albumLink = `/albums/${album.id}/galleries/${album.galleries[0].id}`;
                    }
                    return (
                        <AlbumWrapper key={album.id}>
                            <Album href={albumLink}>
                                <ItemWrapper>
                                    <PictureWrapper>
                                        <Picture src={getImagePreview(album.id, album.image)} />
                                    </PictureWrapper>
                                </ItemWrapper>
                                <Title>{album.name}</Title>
                            </Album>
                            {(admin && token) && (
                                <Edit href={`/albums/${album.id}`}>Edit</Edit>
                            )}
                        </AlbumWrapper>
                    );
                })}
            </Slider>
        </Wrapper>
    );
};


const Wrapper = styled.div`
`;

const Slider = styled.div`
    display: flex;
    padding: 40px;
    align-self: center;
`;

const AlbumWrapper = styled.div`
    position: relative;
    width: auto;
    box-shadow: 0 5px 20px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15);
    border-radius: 4px;
    overflow: hidden;
    margin: 24px;
    cursor: pointer;
    transition: transform 150ms ease-in-out;
    text-decoration: none;

    &:hover {
        transform: scale(1.05);
    }
`;

const Album = styled.a`
    text-decoration: none;
`;

const Edit = styled.a`
    position: absolute;
    left: 20px;
    top: 20px;
    text-decoration: none;
    text-align: center;
    color: #333333;
    padding: 8px 16px;
    box-shadow: 2px 2px 2px #999;
    background-color: #eee;
    transition: all 150ms ease-in-out;

    &:hover {
        background-color: #ccc;
        box-shadow: 1px 1px 2px #999;
    }
`;

const ItemWrapper = styled.div`
    width: 400px;
    height: 300px;
    max-width: 60vw;
    position: relative;
`;

const PictureWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Picture = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const Title = styled.h4`
    text-align: center;
    color: #333333;
`;

export default Albums;

