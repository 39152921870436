// @flow
import { getAction, ActionTypes } from '../actions';

export const initialState = [];


export const reducer = (state = initialState, action) => {
    const { type, id, data } = action;

    switch (type) {
        case getAction('UPDATE_UPLOAD', ActionTypes.SET): {
            const exist = state.filter(item => item.tmpId === id).length > 0;
            if (exist) {
                return state.map(item => {
                    if (item.tmpId === id) return {
                        ...item,
                        ...data,
                    };
                    return item;
                });
            }
            return [
                ...state,
                {
                    tmpId: id,
                    ...data,
                },
            ];
        }
        case getAction('REMOVE_UPLOAD', ActionTypes.SET):
            return state.filter(item => item.tmpId !== id);
        default:
            break;
    }

    return state;
};
