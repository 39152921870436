import React, {useRef, useState, useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux';
// import FsLightbox from 'fslightbox-react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import {getImages, getUploaderItems} from '../selectors';
import { getImagePreview } from '../utils/image';

import styled from 'styled-components';

const calculateCutOff = (len, delta, items) => {
    const cutoff = [];
    let cutsum = 0;
    for(const i in items) {
        const item = items[i];
        const fractOfLen = item.scaletwidth / len;
        cutoff[i] = Math.floor(fractOfLen * delta);
        cutsum += cutoff[i];
    }

    let stillToCutOff = delta - cutsum;
    while(stillToCutOff > 0) {
        for(const i in cutoff) {
            cutoff[i]++;
            stillToCutOff--;
            if (stillToCutOff < 0) break;
        }
    }
    return cutoff;
};

const buildImageRow = (margin, items, containerWidth) => {
    const row = [];
    let len = 0;
    const imgMargin = 2 * margin;
    while(items.length > 0 && len < containerWidth) {
        const item = items.shift();
        row.push(item);
        len += (item.scaletwidth + imgMargin);
    }

    const delta = len - containerWidth;
    if(row.length > 0 && delta > 0) {
        const cutoff = calculateCutOff(len, delta, row);
        for(const i in row) {
            const pixelsToRemove = cutoff[i];
            const item = row[i];
            item.marginLeft = -Math.abs(Math.floor(pixelsToRemove / 2));
            item.vwidth = item.scaletwidth - pixelsToRemove;
        }
    }
    else {
        for(const j in row) {
            const item = row[j];
            item.marginLeft = 0;
            item.vwidth = item.scaletwidth;
        }
    }
    return row;
};

const Gallery = ({
    albumId, galleryId, enableImageSelection, maxRows,
    tagStyle, tileViewportStyle, thumbnailStyle, thumbnailImageComponent,
    onSelectImage,
    rowHeight=220, margin=8,
}) => {
    const photos = useSelector(state => getImages(state, galleryId));
    const tmpItems = useSelector(getUploaderItems);

    const [currentImage, setCurrentImage] = useState(0);
    const [thumbnails, setThumbnails] = useState([]);
    const [images, setImages] = useState([]);

    const [toggler, setToggler] = useState(false);

    const ref = useRef(null);

    const renderThumbs = useCallback((containerWidth, images) => {
        if (!images) return [];
        if (containerWidth == null) return [];

        const items = [...images];
        items.forEach(item => item.scaletwidth = Math.floor(rowHeight * (item.thumbnailWidth / item.thumbnailHeight)));

        const thumbs = [];
        const rows = [];
        while(items.length > 0) {
            rows.push(buildImageRow(margin, items, containerWidth));
        }

        for(const r in rows) {
            for(const i in rows[r]) {
                const item = rows[r][i];
                if(maxRows) {
                    if(r < maxRows) {
                        thumbs.push(item);
                    }
                }
                else {
                    thumbs.push(item);
                }
            }
        }
        return thumbs;
    }, [margin, maxRows, rowHeight]);

    useEffect(() => {
        const savedTmpIds = new Set();

        const images = [];
        photos.forEach(photo => {
            savedTmpIds.add(photo.tmpId);
            images.push({
                src: getImagePreview(albumId, photo, galleryId, 1200),
                thumbnail: getImagePreview(albumId, photo.thumbnail, galleryId, 600),
                thumbnailWidth: photo.thumbnail.width,
                thumbnailHeight: photo.thumbnail.height,
                original: getImagePreview(albumId, photo, galleryId, 1200),
                width: photo.width,
                height: photo.height,
                opacity: 1,
            });
        });

        tmpItems.filter(item => item.width > 0 && !savedTmpIds.has(item.tmpId)).forEach(item => {
            images.push({
                src: item.preview,
                original: item.preview,
                thumbnail: item.preview,
                thumbnailWidth: item.width,
                thumbnailHeight: item.height,
                width: item.width,
                height: item.height,
                caption: item.percent < 100 ? `Uploading... ${item.percent}%` : null,
                thumbnailCaption: item.percent < 100 ? `Uploading... ${item.percent}%` : null,
                opacity: item.percent / 100.0,
                isUploading: item.percent < 100,
            });
        });

        const thumbs = renderThumbs(ref.current?.clientWidth, images);
        setImages(images);
        setThumbnails(thumbs);

    }, [photos, renderThumbs, tmpItems]);

    const _onClickThumbnail = () => {
        return null;
    }

    const _onSelectImage = (index, event) => {
        event.preventDefault();
        onSelectImage && onSelectImage(index, images[index]);
    }


    const getThumbnailStyle = (item) => {
        let rotationTransformValue = undefined;
        switch (item.orientation) {
            case 3:
                rotationTransformValue = "rotate(180deg)";
                break;
            case 6:
                rotationTransformValue = "rotate(90deg)";
                break;
            case 8:
                rotationTransformValue = "rotate(270deg)";
                break;
            case 2:
                rotationTransformValue = "rotateY(180deg)";
                break;
            case 4:
                rotationTransformValue = "rotate(180deg) rotateY(180deg)";
                break;
            case 5:
                rotationTransformValue = "rotate(270deg) rotateY(180deg)";
                break;
            case 7:
                rotationTransformValue = "rotate(90deg) rotateY(180deg)";
                break;
            default:
                break;
        }

        if (item.isSelected || item.isUploading){
            const ratio = (item.scaletwidth / rowHeight);
            let height = 0;
            let width = 0;
            const viewportHeight = (rowHeight - 32);
            const viewportWidth = (item.vwidth - 32);

            if(item.scaletwidth > rowHeight){
                width = item.scaletwidth - 32;
                height = Math.floor(width / ratio);
            }
            else {
                height = rowHeight - 32;
                width = Math.floor(height * ratio);
            }

            const marginTop = -Math.abs(Math.floor((viewportHeight - height) / 2));
            const marginLeft = -Math.abs(Math.floor((viewportWidth - width) / 2));
            return {
                cursor: 'pointer',
                width: width,
                height: height,
                marginLeft: marginLeft,
                marginTop: marginTop,
                transform: rotationTransformValue
            };
        }
        return {
            cursor: 'pointer',
            width: item.scaletwidth,
            height: rowHeight,
            marginLeft: item.marginLeft,
            marginTop: 0,
            transform: rotationTransformValue
        };
    }

    const renderImage = (item, index) => {
        const thumbnailProps = {
            key: `img-${index}`,
            src: item.thumbnail,
            title: item.caption,
            ...getThumbnailStyle(item),
        };

        let width = item.vwidth;
        let height = rowHeight;
        let imageMargin = margin;

        if (item.isSelected || item.isUploading) {
            width -= 32;
            height -= 32;
            imageMargin = 16;
        }

        return (
            <ImageWrapper
                key={index}
                margin={imageMargin}
                width={width}
                height={height}
            >
                <ImageContainer
                    margin={imageMargin}
                    width={width}
                    height={height}
                >
                    <Photo
                        {...thumbnailProps}
                        alt=""
                        loading="lazy"
                        onClick={() => {
                            setToggler(true);
                            setCurrentImage(index);
                        }}
                    />
                </ImageContainer>
                {item.caption && (
                    <PhotoCaption>
                        {item.caption}
                    </PhotoCaption>
                )}
            </ImageWrapper>
        );
    }

    // const sources = [];
    // thumbnails.forEach(thumbnail => sources.push({
    //     original: thumbnail.thumbnail,
    //     thumbnail: thumbnail.thumbnail,
    //     width:L
    // }));

    return (
        <>
            <Wrapper>
                <GalleryContainer ref={ref}>
                    {thumbnails.map((item, index) => renderImage(item, index))}
                </GalleryContainer>
            </Wrapper>
            {toggler && (
                <FullScreenWrapper>
                    <FullScreen>
                        <ImageGallery
                            items={images}
                            showPlayButton={false}
                            startIndex={currentImage}
                            showThumbnails={false}
                            showIndex
                            onClick={() => setToggler(false)}
                        />
                    </FullScreen>
                    <CloseFullScreen onClick={() => setToggler(false)} />
                </FullScreenWrapper>
            )}
        </>
    );
};

const Wrapper = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-top: ${({ noMargin }) => noMargin ? '0px' : '48px'};
    max-width: 1280px;
    padding: 0 24px;
`;

const GalleryContainer = styled.div`
    max-width: 936px;
    margin: 0 0 24px;
    width: 100%;
    border-radius: 8px;
    color: rgba(0,0,0,0.87);
    background-color: rgb(255,255,255);
`;

const FullScreenWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.95);
`;

const FullScreen = styled.div`
    position: relative;
    padding: 20px;
`;

const CloseFullScreen = styled.div`
    position: absolute;
    left: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 3px;
        background-color: #eee;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;

const ImageWrapper = styled.div`
    margin: ${({ margin }) => `${margin}px`};
    user-select: none;
    position: relative;
    float: left;
    background: #eee;
    padding: 0px;
`;

const ImageContainer = styled.div`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    overflow: hidden;
    box-shadow: 0 2px 8px #333;
`;

const Photo = styled.img`
    cursor: pointer;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    margin-left: ${({ marginLeft }) => `${marginLeft}px`};
    margin-top: ${({ marginTop }) => `${marginTop}px`};
    transform: ${({ rotationTransformValue }) => rotationTransformValue};
`;

const PhotoCaption = styled.div`
    background: white;
    height: 100%;
    width: 100%;
    margin: 0;
    user-select: text;
    overflow: hidden;
`;

export default Gallery;

