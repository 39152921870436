import { call, put, takeEvery, select } from 'redux-saga/effects';

import { getAction, ActionTypes } from '../actions';
import { getAuthToken, getAlbums, getAlbum, getGallery } from '../selectors';
import { reader, deleter, updater, creator } from './common';

const loadAlbums = function* loadAlbums(action) {
    const authToken = yield select(getAuthToken);

    const cached = yield select(getAlbums);
    if (Object.keys(cached).length === 0) {
        const data = yield call(reader, {
            endpoint: 'albums',
            header: authToken ? {
                'X-Coder-Token': authToken,
            } : {},
        });

        yield put({
            type: getAction('ALBUMS', ActionTypes.LOAD, true),
            data: data.response.data,
        });
    }
};

const loadAlbum = function* loadAlbum(action) {
    const authToken = yield select(getAuthToken);
    const { id } = action;

    const cached = yield select(getAlbum, id);
    if (Object.keys(cached).length === 0) {
        const data = yield call(reader, {
            endpoint: `albums/${id}`,
            header: authToken ? {
                'X-Coder-Token': authToken,
            } : {},
        });

        yield put({
            type: getAction('ALBUM', ActionTypes.LOAD, true),
            data: data.response.data,
        });
    }
};

const createAlbum = function* createAlbum(action) {
    const authToken = yield select(getAuthToken);

    const {cback} = action;

    const data = yield call(creator, {
        endpoint: 'albums',
        data: {
            ...action.data,
        },
        header: authToken ? {
            'X-Coder-Token': authToken,
        } : {},
    });

    cback && cback(data.response.data);

    yield put({
        type: getAction('ALBUM', ActionTypes.CREATE, true),
        data: data.response.data,
    });
};

const updateAlbum = function* updateAlbum(action) {
    const authToken = yield select(getAuthToken);

    const {cback} = action;

    const data = yield call(updater, {
        endpoint: `albums/${action.id}`,
        data: {
            ...action.data,
        },
        header: authToken ? {
            'X-Coder-Token': authToken,
        } : {},
    });

    cback && cback(data.response.data);

    yield put({
        type: getAction('ALBUM', ActionTypes.UPDATE, true),
        data: data.response.data,
    });
};


const createGallery = function* createGallery(action) {
    const authToken = yield select(getAuthToken);

    const {id, cback} = action;

    const data = yield call(creator, {
        endpoint: `albums/${id}/galleries/`,
        data: {
            ...action.data,
        },
        header: authToken ? {
            'X-Coder-Token': authToken,
        } : {},
    });

    cback && cback(data.response.data);

    yield put({
        type: getAction('ALBUM', ActionTypes.CREATE, true),
        data: data.response.data,
    });
};


const loadGallery = function* loadGallery(action) {
    const authToken = yield select(getAuthToken);
    const { albumId, galleryId } = action;

    const cached = yield select(getGallery, galleryId);
    if (Object.keys(cached).length === 0) {
        const data = yield call(reader, {
            endpoint: `albums/${albumId}/galleries/${galleryId}`,
            header: authToken ? {
                'X-Coder-Token': authToken,
            } : {},
        });

        yield put({
            type: getAction('GALLERY', ActionTypes.LOAD, true),
            data: data.response.data,
        });
    }
};


const updateGallery = function* updateGallery(action) {
    const authToken = yield select(getAuthToken);

    const {cback} = action;

    const data = yield call(updater, {
        endpoint: `albums/${action.albumId}/galleries/${action.galleryId}`,
        data: {
            ...action.data,
        },
        header: authToken ? {
            'X-Coder-Token': authToken,
        } : {},
    });

    cback && cback(data.response.data);

    yield put({
        type: getAction('GALLERY', ActionTypes.UPDATE, true),
        data: data.response.data,
    });
};


const saveGalleryImages = function* saveGalleryImages(action) {
    const authToken = yield select(getAuthToken);

    const {collectionId, images, imagesOrder, albumId, galleryId} = action;

    for (let a = 0; a < images.length; a += 1) {
        const image = images[a];

        const imageData = {
            type: image.fileType,
            publicId: image.publicId,
            width: image.width,
            height: image.height,
            thumbnail: {
                publicId: image.thumbnail.publicId,
                width: image.thumbnail.width,
                height: image.thumbnail.height,
            },
        };

        const data = yield call(creator, {
            //TODO
            endpoint: `albums/${albumId}/galleries/${galleryId}/images`,
            data: {
                id: collectionId,
                data: imageData,
            },
            header: authToken ? {
                'X-Coder-Token': authToken,
            } : {},
        });

        const {id} = data.response.data;

        yield put({
            type: getAction('REMOVE_UPLOAD', ActionTypes.SET),
            id,
        });
        imagesOrder.push(id);
    }

    const collection = yield call(updater, {
        endpoint: `albums/${albumId}/galleries/${galleryId}`,
        data: {
            id: collectionId,
            imagesOrder,
        },
        header: authToken ? {
            'X-Coder-Token': authToken,
        } : {},
    });

    alert('DONE')

    // yield put({
    //     type: getAction('COLLECTION', ActionTypes.UPDATE),
    //     id: collectionId,
    //     isNew: false,
    //     data: collection.response.data,
    // });
}


const rootSaga = function* () {
    yield takeEvery(getAction('ALBUMS', ActionTypes.LOAD), loadAlbums);
    yield takeEvery(getAction('ALBUM', ActionTypes.LOAD), loadAlbum);
    yield takeEvery(getAction('ALBUM', ActionTypes.CREATE), createAlbum);
    yield takeEvery(getAction('ALBUM', ActionTypes.UPDATE), updateAlbum);
    yield takeEvery(getAction('GALLERY', ActionTypes.CREATE), createGallery);
    yield takeEvery(getAction('GALLERY', ActionTypes.LOAD), loadGallery);
    yield takeEvery(getAction('GALLERY', ActionTypes.UPDATE), updateGallery);
    yield takeEvery(getAction('GALLERY_IMAGES', ActionTypes.UPDATE), saveGalleryImages);
}

export default rootSaga;
