import { call, put, takeEvery } from 'redux-saga/effects';

import { getAction, ActionTypes } from '../actions';
import { creator, reader } from './common';

const authenticate = function* authenticate(action) {
    const {email, password, cback} = action;

    const data = yield call(creator, {
        endpoint: `login`,
        data: {
            email, password,
        },
    });

    yield put({
        type: getAction('LOGIN', ActionTypes.CREATE, true),
        data: data?.response?.data,
    });

    cback && cback(data?.response?.data?.token);
};

const getToken = function* getToken(action) {
    const {token} = action;

    const data = yield call(reader, {
        endpoint: `getToken`,
        header: {
            'X-Coder-Token': token,
        }
    });

    yield put({
        type: getAction('TOKEN', ActionTypes.CREATE, true),
        data: data?.response?.data,
    });
};


const rootSaga = function* () {
    yield takeEvery(getAction('LOGIN', ActionTypes.CREATE), authenticate);
    yield takeEvery(getAction('TOKEN', ActionTypes.CREATE), getToken);
}

export default rootSaga;
